<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" title="发送实名认证邀请" :close-on-click-modal="false"
    width="420px">
    <div class="mb-40 mt-40 conentbox">
      <template  v-if="state.realStatus==1">
        <div class="ta-c mb-10">当前法定代表人已实名认证</div>
        <div class="ta-c">可发送短信通知法定代表人进行企业认证</div>
      </template>
      <template  v-else>
        <div class="ta-c mb-10">当前法定代表人暂未实名认证</div>
        <div class="ta-c">请选择验证方式（二选一）通知法定代表人进行认证</div>
      </template>
      <div class="buts dp-f jc-c mt-20" v-if="state.realStatus!=1">
        <div class="but dp-fc lh-14 cu-p" v-for="(el, ind) in state.buts" :key="ind" :class="state.Index==ind?'butac':''" @click="state.Index=ind">
          {{ el.title }}
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='66' height='32' style="border-radius: 2px;" title="确定" CSStype=2
          @buttonclick="submit()" v-model:loading="dialogloading" />
        <oabutton class="searcML" width='66' height='32' style="border-radius: 2px;" CSStype=3 title="取消"
          @buttonclick="dialogVisible = false" />
      </span>
    </template>
  </el-dialog>
  <fill ref="fillRef" @Emit="emit('setStep',3)"></fill>
  <authentication ref="authenticationRef" @Emit="emit('setStep',3)" v-model:LegalPersonInfo="state.LegalPersonInfo"></authentication>
  <face ref="faceRef"/>
</template>
<script setup>
import { ref,unref, reactive, defineEmits } from "vue";
import { httpToken } from "@/utils/request";
import { useStore } from "vuex";
import { handleMessage } from "@/utils/server/confirm.js"
import qs from "qs";
import fill from "./fill.vue"//回填验证码
import authentication from "./authentication.vue"//身份验证弹框
import face from "../../face.vue"//人脸识别
const emit = defineEmits(['Emit','setStep'])
const store = useStore();
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const authenticationRef = ref()//身份验证弹框ref
const faceRef = ref();//人脸识别弹出框ref
const fillRef= ref();
const state = reactive({
  realStatus: 0,//当前公司法定代表人是否实名
  // buts: [{ title: '人脸识别' }, { title: '短信验证码' }],
  buts: [{ title: '短信验证码' }],//人脸识别隐藏
  Index: 0,//未实名的认证方式 0 人脸识别 1 短信验证码,
  LegalPersonInfo:null,//法定代表人基本信息
})
const show = (() => {
  if(!state.LegalPersonInfo){
    handleMessage('法定代表人基本信息还未获取')
    return
  }
  dialogloading.value = false
  dialogVisible.value = true
})
const submit = (() => {
  // 发送验证码
  dialogloading.value = false
  dialogVisible.value = false
  if(state.realStatus==0){
    unref(fillRef).show()
    // if(state.Index==1){//发送验证码
    //   unref(fillRef).show()
    // }else{//人脸
    //   unref(faceRef).show()
    // }
  }else{//已实名
    unref(authenticationRef).check()
  }
})
// 获取法定代表人信息
const getInfo = () => {
  httpToken({
    method: "post",
    url: '/admin/certification/getLegalPersonByCompanyId',
    data: qs.stringify({
      companyId: store.state.realName.companyInfo.companyId
    })
  }).then((res) => {
    state.LegalPersonInfo=res.data
    state.realStatus=res.data.realStatus
  })
}
getInfo()
defineExpose({
  show
})
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";
.conentbox {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .buts {
    .but {
      width: 120px;
      height: 38px;
      background: #F5F5F5;
      border-radius: 4px;
    }
    .butac{
      border: 1px solid $active-theme;
      background: $active-alpha;
    }
    .but+.but {
      margin-left: 20px;
    }
  }
}
</style>